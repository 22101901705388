<template>
  <x-page
    :breadcrumbs="breadcrumbs"
  >
    <div class="dashboard pt-5">
      <div class="dashboard__heading headline-2 mb-5">
        <x-icon
          class="back-button"
          name="chevron-left"
          size="s"
          @click="$router.push('/new_dashboards')"
        />
        <x-icon
          :name="getMarketplaceName(marketplace_id)"
          size="s"
        />
        {{ shop_name }}
      </div>
      <div
        v-if="loadingData"
        class="dashboard__filters mb-5"
        :class="{'not-permit-by-tariff': !isFiltersPermitByTariff}"
      >
        <new-dashboards-filters
          :period="period"
          @change:period="onPeriodChange"
          @change:show-by="onShowBy"
        />
      </div>
      <div
        v-if="loadingData"
        class="dashboard__row"
      >
        <the-summary
          :indicators="summary"
          :loading="isLoading.includes('tile')"
        />
      </div>
      <template>
        <div class="dashboard__row">
          <simple-scatter
            heading="is_shop_active"
            :points="is_shop_active"
            :loading="isLoading.includes('is_shop_active')"
          />
        </div>
      </template>
      <template>
        <div class="headline-4 mb-5">
          Заказы
        </div>
        <div class="dashboard__row content-columns content-columns_content_a-b">
          <div class="column column_layout_a">
            <simple-area
              heading="orders_money"
              :points="orders_money"
              :measure="Measures.RUBLES"
              :show-day="show_day"
              :show-week="show_week"
              :loadind="isLoading.includes('metric')"
            />
          </div>

          <div class="column column_layout_b">
            <simple-area
              heading="orders_count"
              :points="orders_count"
              :measure="Measures.QUANTITY"
              :show-day="show_day"
              :show-week="show_week"
              :loadind="isLoading.includes('metric')"
            />
          </div>
          <div class="column column_layout_a">
            <simple-area
              heading="cancellations_money"
              :points="cancellations_money"
              :measure="Measures.RUBLES"
              :show-day="show_day"
              :show-week="show_week"
              :loadind="isLoading.includes('metric')"
            />
          </div>

          <div class="column column_layout_b">
            <simple-area
              heading="cancellations"
              :points="cancellations"
              :measure="Measures.QUANTITY"
              :show-day="show_day"
              :show-week="show_week"
              :loadind="isLoading.includes('metric')"
            />
          </div>

          <div class="column column_layout_a">
            <multiple-columns
              heading="adding_and_routing"
              :points="adding_and_routing"
              :measure="Measures.QUANTITY"
              :show-day="show_day"
              :show-week="show_week"
              legend
              :colors="['#1EABF1', '#8DC252', '#F2453D', '#F7A738', '#A926FB']"
              :loadind="isLoading.includes('metric')"
            />
          </div>

          <div class="column column_layout_b">
            <multiple-lines
              heading="conversions"
              :points="conversions"
              :measure="Measures.PERCENT"
              :show-day="show_day"
              :show-week="show_week"
              legend
              :loadind="isLoading.includes('metric')"
            />
          </div>
        </div>
        <div class="headline-4 mb-5">
          Реклама
        </div>
        <div class="dashboard__row content-columns content-columns_content_a-b">
          <div class="column column_layout_a">
            <simple-area
              heading="expense_adv_money"
              :points="expense_adv_money"
              :measure="Measures.RUBLES"
              :show-day="show_day"
              :show-week="show_week"
              :loadind="isLoading.includes('metric')"
            />
          </div>

          <div class="column column_layout_b">
            <multiple-lines
              heading="expense_adv_money_type"
              :points="expense_adv_money_type"
              :measure="Measures.RUBLES"
              :show-day="show_day"
              :show-week="show_week"
              legend
              :loadind="isLoading.includes('metric')"
            />
          </div>
          <div class="column column_layout_a">
            <multiple-columns
              heading="views_clicks_count"
              :points="views_clicks_count"
              :measure="Measures.QUANTITY"
              :show-day="show_day"
              :show-week="show_week"
              legend
              :colors="['#1EABF1', '#8DC252', '#F2453D', '#F7A738', '#A926FB']"
              :loadind="isLoading.includes('metric')"
            />
          </div>

          <div class="column column_layout_b">
            <multiple-lines
              heading="conversions"
              :points="adv_conversions"
              :measure="Measures.PERCENT"
              :show-day="show_day"
              :show-week="show_week"
              legend
              :loadind="isLoading.includes('metric')"
            />
          </div>
          <div class="column column_layout_a">
            <simple-area
              heading="adv_ordered_money"
              :points="adv_ordered_money"
              :measure="Measures.RUBLES"
              :show-day="show_day"
              :show-week="show_week"
              :loadind="isLoading.includes('metric')"
            />
          </div>

          <div class="column column_layout_b">
            <simple-area
              heading="adv_ordered_quantity"
              :points="adv_ordered_quantity"
              :measure="Measures.QUANTITY"
              :show-day="show_day"
              :show-week="show_week"
              :loadind="isLoading.includes('metric')"
            />
          </div>
          <div class="column column_layout_a">
            <simple-lines
              heading="drr"
              :points="drr"
              :measure="Measures.PERCENT"
              :show-day="show_day"
              :show-week="show_week"
              legend
              :loadind="isLoading.includes('metric')"
            />
          </div>

          <div class="column column_layout_b">
            <simple-lines
              heading="ctr"
              :points="ctr"
              :measure="Measures.PERCENT"
              :show-day="show_day"
              :show-week="show_week"
              legend
              :loadind="isLoading.includes('metric')"
            />
          </div>
        </div>
        <div class="headline-4 mb-5">
          Товары
        </div>
        <div class="dashboard__row content-columns content-columns_content_a-b">
          <div class="column column_layout_a">
            <multiple-lines
              heading="products_in_selling_FBO_FBS"
              :points="products_in_selling_FBO_FBS"
              :measure="Measures.QUANTITY"
              :show-day="show_day"
              :show-week="show_week"
              legend
              :loadind="isLoading.includes('metric')"
            />
          </div>

          <div class="column column_layout_b">
            <multiple-lines
              heading="products_with_0_stocks_FBO_FBS"
              :points="products_with_0_stocks_FBO_FBS"
              :measure="Measures.QUANTITY"
              :show-day="show_day"
              :show-week="show_week"
              legend
              :loadind="isLoading.includes('metric')"
            />
          </div>
          <div class="column column_layout_a">
            <multiple-lines
              heading="working_with_content"
              :points="working_with_content"
              :measure="Measures.QUANTITY"
              :show-day="show_day"
              :show-week="show_week"
              legend
              :loadind="isLoading.includes('metric')"
            />
          </div>

          <div class="column column_layout_b">
            <simple-list
              heading="top_5"
              :points="top_5"
              :loadind="isLoading.includes('metric')"
            />
          </div>
        </div>
      </template>
    </div>
  </x-page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
import formatDate from '@/utils/date-formatter'
import formatterDataDashboards from '@/utils/formatterDataDashboards'
import toastNotification from '@/utils/toast-notification'
import GraficsName from '@/constants/dashboard_mertics'
import NewDashboardsFilters from '@/components/Dashboard/NewDashboardShopFilters.vue'
import TheSummary from '@/components/Dashboard/NewSummary.vue'
import SimpleScatter from '@/components/Dashboard/Graphs/SimpleScatter.vue'
import MultipleColumns from '@/components/Dashboard/Graphs/MultipleColumns.vue'
import MultipleLines from '@/components/Dashboard/Graphs/MultipleLines.vue'
import SimpleArea from '@/components/Dashboard/Graphs/SimpleArea.vue'
import Measures from '@/constants/measure-name'
import '@/styles/dashboards.styl'
import { getMarketplaceIcon } from '@/utils/getMarketplaceIcon'
import SimpleList from '../components/Dashboard/Graphs/SimpleList'
import SimpleLines from '../components/Dashboard/Graphs/SimpleLines'

const { mapActions } = createNamespacedHelpers('dashboards')
const { mapState } = createNamespacedHelpers('profile')

export default {
  name: 'HomeWithDashboards',

  components: {
    SimpleLines,
    SimpleList,
    NewDashboardsFilters,
    TheSummary,
    SimpleScatter,
    SimpleArea,
    MultipleColumns,
    MultipleLines
  },

  data () {
    return {
      breadcrumbs: [{ text: 'Сводка', to: { name: 'new_dashboards' } }],
      Measures,
      GraficsName,
      // TODO: isLoading: false and remove isFiltersPermitByTariff, isSummaryPermitByTariff
      columns: [],
      metrics: [],
      metricList: [],
      dates: [],
      checkedMetrics: [],
      selectedMetrics: [],
      freeColorIndexs: [],
      colors: ['#1EABF1', '#F2453D', '#8DC252', '#F7A738', '#8554E1', '#D2927E', '#E725C8'],
      colorIndex: 0,
      isLoading: [],
      loadingData: true,
      isFiltersPermitByTariff: true,
      isSummaryPermitByTariff: true,
      is_shop_active: {},
      orders_money: {},
      orders_count: {},
      redemption_percentage: {},
      cancellations_money: {},
      cancellations: {},
      conversions: {},
      adding_and_routing: {},
      expense_adv_money: {},
      expense_adv_money_type: {},
      adv_ordered_quantity: {},
      adv_ordered_money: {},
      drr: {},
      ctr: {},
      products_with_0_stocks_FBO_FBS: {},
      products_in_selling_FBO_FBS: {},
      working_with_content: {},
      views_clicks_count: {},
      adv_conversions: {},
      summary: [],
      shop_name: '',
      marketplace_id: null,
      show_day: true,
      show_week: true,
      top_5: {},
      period: {
        start: moment(new Date()).subtract(8, 'days').toDate(),
        end: moment(new Date()).subtract(1, 'days').toDate()
      }
    }
  },

  computed: {
    ...mapState(['user']),

    id () {
      return this.$route.params.id
    },

    marketplaceName () {
      return this.$route.params.marketplace
    }
  },

  created () {
    this.summary = [{
      indicator_type: 'boxes',
      indicator_value: '',
      dimension: 'counter'
    }, {
      indicator_type: 'money',
      indicator_value: '',
      dimension: 'rubles'
    }, {
      indicator_type: 'adv',
      indicator_value: '',
      dimension: 'rubles'
    }, {
      indicator_type: 'percent',
      indicator_value: '',
      dimension: 'percent'
    }]
    // TODO: delete when yandex back be ready
    if (this.marketplaceName === 'yandex-market') {
      this.loadingData = false
    }
  },

  mounted () {
    this.isLoading = ['is_shop_active', 'tile', 'metric']
    const period = localStorage.getItem('period')
    if (period) {
      this.period = JSON.parse(period)
    }
    this.getAllData()
  },

  methods: {
    ...mapActions([
      'getMetricsList',
      'getShopActivity',
      'getShopOrderFull',
      'getMetricsStatistic',
      'getTop5Product'
    ]),

    getMarketplaceName (id) {
      let result = ''
      switch (id) {
        case 1:
          result = 'ali'
          break
        case 2:
          result = 'ozon'
          break
        case 4:
          result = 'yandex-market'
          break
        case 9:
          result = 'wildberries'
          break
        default:
          result = ''
      }
      getMarketplaceIcon(result)
      return result
    },

    async getAllData () {
      const payload = {
        id: this.id,
        date_from: formatDate(this.period.start, 'YYYY-MM-DD'),
        date_to: formatDate(this.period.end, 'YYYY-MM-DD')
      }
      this.getShopsActivityList(payload)
      this.getShopOrder(payload)
      const data = await this.getMetricList(payload)
      this.getMetricsStatisticList(payload, data)
      const payloadForTop = {
        shop_ids: [this.id],
        date_from: formatDate(this.period.start, 'YYYY-MM-DD'),
        date_to: formatDate(this.period.end, 'YYYY-MM-DD')
      }
      const top5 = await this.getTop5Product(payloadForTop)
      this.top_5 = top5.data[0]
    },

    async getMetricList (payload) {
      let metricList = []
      try {
        this.isLoading.push('metric')
        const { isSuccess, data, error } = await this.getMetricsList(payload)
        if (isSuccess) {
          metricList = data
        } else {
          toastNotification.error(error.msg)
        }
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'metric')
      }
      return metricList
    },

    async getMetricsStatisticList (payload, metricList) {
      const newPayload = { ...payload }
      newPayload.metrics = metricList.map(metric => metric.name)
      try {
        this.isLoading.push('metric')
        const { isSuccess, data, error } = await this.getMetricsStatistic(newPayload)
        if (isSuccess) {
          const arrData = [data]
          formatterDataDashboards.formatData(this, arrData, 'orders_money')
          formatterDataDashboards.formatData(this, arrData, 'orders_count')
          formatterDataDashboards.formatData(this, arrData, 'cancellations_money')
          formatterDataDashboards.formatData(this, arrData, 'cancellations')
          formatterDataDashboards.formatData(this, arrData, 'added_to_cart')
          formatterDataDashboards.formatData(this, arrData, 'go_to_card')
          formatterDataDashboards.formatData(this, arrData, 'conversion_added_to_cart')
          formatterDataDashboards.formatData(this, arrData, 'conversion_to_order')
          formatterDataDashboards.formatData(this, arrData, 'expense_adv_money')
          formatterDataDashboards.formatData(this, arrData, 'expense_adv_money__search')
          formatterDataDashboards.formatData(this, arrData, 'expense_adv_money__product')
          formatterDataDashboards.formatData(this, arrData, 'expense_adv_money__catalog')
          formatterDataDashboards.formatData(this, arrData, 'expense_adv_money__search_catalog')
          formatterDataDashboards.formatData(this, arrData, 'expense_adv_money__automatic')
          formatterDataDashboards.formatData(this, arrData, 'paid_views_count')
          formatterDataDashboards.formatData(this, arrData, 'adv_clicks')
          formatterDataDashboards.formatData(this, arrData, 'conversion_added_to_cart_from_adv')
          formatterDataDashboards.formatData(this, arrData, 'conversion_to_order_from_adv')
          formatterDataDashboards.formatData(this, arrData, 'adv_ordered_money')
          formatterDataDashboards.formatData(this, arrData, 'adv_ordered_quantity')
          formatterDataDashboards.formatData(this, arrData, 'drr')
          formatterDataDashboards.formatData(this, arrData, 'ctr')
          formatterDataDashboards.formatData(this, arrData, 'products_in_selling_fbo')
          formatterDataDashboards.formatData(this, arrData, 'products_in_selling_fbs')
          formatterDataDashboards.formatData(this, arrData, 'products_with_zero_stock_fbo')
          formatterDataDashboards.formatData(this, arrData, 'products_with_zero_stock_fbs')
          formatterDataDashboards.formatData(this, arrData, 'expired_feedbacks_count')
          formatterDataDashboards.formatData(this, arrData, 'products_with_errors')
          this.adv_conversions = {
            dates: [...this.conversion_added_to_cart_from_adv.dates],
            values: [...this.conversion_added_to_cart_from_adv.values.map(_ => ({
              ..._,
              name: 'conversion_added_to_cart_from_adv',
              id: 'conversion_added_to_cart_from_adv'
            })),
            ...this.conversion_to_order_from_adv.values.map(_ => ({
              ..._,
              name: 'conversion_to_order_from_adv',
              id: 'conversion_to_order_from_adv'
            }))]
          }
          this.views_clicks_count = {
            dates: [...this.paid_views_count.dates],
            values: [...this.paid_views_count.values.map(_ => ({
              ..._,
              name: 'paid_views_count',
              id: 'paid_views_count'
            })),
            ...this.adv_clicks.values.map(_ => ({
              ..._,
              name: 'adv_clicks',
              id: 'adv_clicks'
            }))]
          }
          this.working_with_content = {
            dates: [...this.products_with_errors.dates],
            values: [...this.products_with_errors.values.map(_ => ({
              ..._,
              name: 'products_with_errors',
              id: 'products_with_errors'
            })),
            ...this.expired_feedbacks_count.values.map(_ => ({
              ..._,
              name: 'expired_feedbacks_count',
              id: 'expired_feedbacks_count'
            }))]
          }
          this.products_in_selling_FBO_FBS = {
            dates: [...this.orders_money.dates],
            values: [...this.products_in_selling_fbo.values.map(_ => ({
              ..._,
              name: 'products_in_selling_fbo',
              id: 'products_in_selling_fbo'
            })),
            ...this.products_in_selling_fbs.values.map(_ => ({
              ..._,
              name: 'products_in_selling_fbs',
              id: 'products_in_selling_fbs'
            }))]
          }
          this.products_with_0_stocks_FBO_FBS = {
            dates: [...this.products_with_zero_stock_fbo.dates],
            values: [...this.products_with_zero_stock_fbo.values.map(_ => ({
              ..._,
              name: 'products_with_zero_stock_fbo',
              id: 'products_with_zero_stock_fbo'
            })),
            ...this.products_with_zero_stock_fbs.values.map(_ => ({
              ..._,
              name: 'products_with_zero_stock_fbs',
              id: 'products_with_zero_stock_fbs'
            }))]
          }
          this.adding_and_routing = {
            dates: [...this.added_to_cart.dates],
            values: [...this.added_to_cart.values.map(_ => ({
              ..._,
              name: 'added_to_cart',
              id: 'added_to_cart'
            })),
            ...this.go_to_card.values.map(_ => ({
              ..._,
              name: 'go_to_card',
              id: 'go_to_card'
            }))]
          }
          this.conversions = {
            dates: [...this.conversion_added_to_cart.dates],
            values: [...this.conversion_added_to_cart.values.map(_ => ({
              ..._,
              name: 'conversion_added_to_cart',
              id: 'conversion_added_to_cart'
            })),
            ...this.conversion_to_order.values.map(_ => ({
              ..._,
              name: 'conversion_to_order',
              id: 'conversion_to_order'
            }))]
          }
          this.expense_adv_money_type = {
            dates: [...this.expense_adv_money__search.dates],
            values: [...this.expense_adv_money__search.values.map(_ => ({
              ..._,
              name: 'expense_adv_money__search',
              id: 'expense_adv_money__search'
            })),
            ...this.expense_adv_money__product.values.map(_ => ({
              ..._,
              name: 'expense_adv_money__product',
              id: 'expense_adv_money__product'
            })),
            ...this.expense_adv_money__catalog.values.map(_ => ({
              ..._,
              name: 'expense_adv_money__catalog',
              id: 'expense_adv_money__catalog'
            })),
            ...this.expense_adv_money__search_catalog.values.map(_ => ({
              ..._,
              name: 'expense_adv_money__search_catalog',
              id: 'expense_adv_money__search_catalog'
            })),
            ...this.expense_adv_money__automatic.values.map(_ => ({
              ..._,
              name: 'expense_adv_money__automatic',
              id: 'expense_adv_money__automatic'
            }))]
          }
        } else {
          toastNotification.error(error.msg)
        }
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'metric')
      }
    },

    async getShopsActivityList (payload) {
      try {
        this.isLoading.push('is_shop_active')
        const start = new Date(payload.date_from)
        const end = new Date(payload.date_to)
        const daysLag = Math.ceil(Math.abs(end.getTime() - start.getTime()) / (1000 * 3600 * 24))
        const newPayload = { ...payload }
        if (daysLag > 30) {
          const newStart = moment(end).subtract(30, 'days').toDate()
          newPayload.date_from = formatDate(newStart, 'YYYY-MM-DD')
        }
        this.is_shop_active = {}
        const { isSuccess, data, error } = await this.getShopActivity(newPayload)
        if (isSuccess) {
          formatterDataDashboards.formatData(this, data, 'is_shop_active')
        } else {
          toastNotification.error(error.msg)
        }
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'is_shop_active')
      }
    },

    async getShopOrder (payload) {
      try {
        this.isLoading.push('tile')
        const { isSuccess, data, error } = await this.getShopOrderFull(payload)
        if (isSuccess) {
          this.shop_name = data[0].shop.name
          this.marketplace_id = data[0].shop.marketplace_id
          this.summary[0].indicator_value = data[0].orders_count
          this.summary[1].indicator_value = data[0].orders_money
          this.summary[2].indicator_value = data[0].expense_adv_money
          this.summary[3].indicator_value = data[0].redemption_percentage
        } else {
          toastNotification.error(error.msg)
        }
      } finally {
        this.isLoading = this.isLoading.filter(loader => loader !== 'tile')
      }
    },

    onShowBy (param, value) {
      this[param] = value
    },

    getBackgroundStyle (name, value) {
      let isNegative = false
      if (name === 'drr' ||
        name === 'expired_feedbacks_count' ||
        name === 'products_with_errors' ||
        name === 'expired_fbs_orders' ||
        name === 'wb_avg_delivery_time' ||
        name === 'wb_commission' ||
        name === 'products_with_bad_description' ||
        name === 'products_in_quarantine' ||
        name === 'negative_index' ||
        name === 'questions_unanswered' ||
        name === 'products_0_stock_fbo' ||
        name === 'products_0_stock_fbs' ||
        name === 'products_1_stock_fbo' ||
        name === 'products_1_stock_fbs' ||
        name === 'products_2_5_stock_fbo' ||
        name === 'products_2_5_stock_fbs' ||
        name === 'disputed_orders' ||
        name === 'fbs_delivering_today' ||
        name === 'cancelled_orders_by_seller' ||
        name === 'cancelled_orders_rating' ||
        name === 'shipment_delay_rating' ||
        name === 'products_cancelled' ||
        name === 'products_returned' ||
        name === 'cancelled_orders_by_seller_rating' ||
        name === 'products_with_zero_stock_fbo' ||
        name === 'products_with_zero_stock_fbs') {
        isNegative = true
      }
      const normalMetrics = name === 'paid_views_count' ||
        name === 'ozon_expense_template_adv_money' ||
        name === 'ozon_expense_search_adv_money' ||
        name === 'ozon_expense_brand_adv_money' ||
        name === 'advert_orders_share' ||
        name === 'expense_adv_money__search' ||
        name === 'expense_adv_money__product' ||
        name === 'expense_adv_money__catalog' ||
        name === 'expense_adv_money'
      const normal = value === '0.00' || value === '0' || normalMetrics
      const positive = isNegative ? value < 0 : value > 0
      return normal ? '' : positive ? 'positive' : 'negative'
    },

    onPeriodChange (period) {
      this.period = period
      localStorage.setItem('period', JSON.stringify(period))
      this.getAllData()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .back-button
    cursor pointer
  .heading
    &__row
      background-color #ffffff
      padding 30px 20px
      &__button
        gap 5px
      &__head
        font-size 20px
        font-family 'proxima_nova'
        color #3C3C47
  .toggle-btn
    border 1px solid #CACAD3
    border-radius: 4px;
    color #3C3C47
    font-family 'proxima_nova'
    padding: 8px 10px 8px 6px;
    display flex
    align-items center
    justify-content space-between
  .metrics-grid
    &__group-name
      display inline-block
      font-size 14px
      margin 4px 8px
    &__block
      min-width 6px
      height 100%
      &__color
        height 100%
        min-width 6px
        margin-right 6px
        &.color_red
          background-color #F2453D
        &.color_blue
          background-color #1EABF1
        &.color_orange
          background-color #F7A738
        &.color_green
          background-color #8DC252
        &.color_purple
          background-color #8554E1
        &.color_brown
          background-color #D2927E
        &.color_pink
          background-color #E725C8
    &__meta
      width 100%
      &__value
        width 100%
        margin-top 5px
        text-align left
        background-color #F7F7F8
      .positive
        background-color #EEFFF6
      .negative
        background-color #FFEBEE
</style>
